import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../components/layout';
import Seo from '../../components/seo';
import WorkCard from '../../components/work-card';
import workList from '../../../content/works/works-list';

const useStyles = makeStyles(theme => ({
    title: {
        paddingTop: 40,
        paddingBottom: 50,
        textAlign: 'center',
    },
    cardWrapper: {
        padding: 30,
    }
}));

const WorksIndex = ({ data, location }) => {
    const classes = useStyles();

    return (
        <Layout location={location} title="Works">
            <Seo title="All Works" />
            <Container className={classes.container}>
                <Typography variant="h1" className={classes.title}>Works</Typography>

                { workList.map((work) => (
                    <div
                        key={`${work.eventName}-${work.eventDate}`}
                        className={classes.cardWrapper}
                    >
                        <WorkCard {...work} />
                    </div>
                ))}
            </Container>
        </Layout>
    );
};

export default WorksIndex;
