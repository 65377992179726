import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 20,
        textAlign: 'left',
        margin: 20,
        display: 'flex',

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        },
    },
    image: {
        height: 'auto',
        width: '50%',

        [theme.breakpoints.down('xs')]: {
            height: '25%',
            width: '100%',
        },
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    eventName: {
        textAlign: 'left'
    },
    textWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: 10,
        paddingBottom: 10,
    },
    eventDate: {
        fontSize: '1.1rem'
    },
    eventLocation: {
        fontSize: '1.1rem'
    },
    description: {

    },
    actions: {
        marginBottom: 8,
        justifyContent: 'center'
    }
}));

const WorkCard = ({
    eventName,
    eventDate,
    eventLocation,
    description,
    imageSrc,
    primaryLink,
    primaryLinkLabel,
    secondaryLink,
    secondaryLinkLabel,
}) => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            { imageSrc && (
                <CardMedia
                    className={classes.image}
                    component="img"
                    alt=""
                    image={imageSrc}
                    title=""
                />
            )}
            <div className={classes.details}>
                <CardContent className={classes.content}>
                    <Typography
                        variant="h2"
                        color="primary"
                        className={classes.eventName}
                    >
                        {eventName}
                    </Typography>

                    <div className={classes.textWrapper}>
                        <Typography
                            variant="subtitle1"
                            component="p"
                            color="textSecondary"
                            className={classes.eventDate}
                        >
                            {eventDate}
                        </Typography>

                        { eventLocation && (
                            <Typography
                                variant="subtitle1"
                                component="p"
                                color="textSecondary"
                                className={classes.eventLocation}
                            >
                                &nbsp;- {eventLocation}
                            </Typography>
                        )}
                    </div>

                    <Typography
                        variant="body1"
                        component="p"
                        className={classes.description}
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                </CardContent>
                <CardActions className={classes.actions}>
                    <Button
                        size="small"
                        color="primary"
                        href={primaryLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {primaryLinkLabel}
                    </Button>

                    { secondaryLink && (
                        <Button
                            size="small"
                            color="primary"
                            href={secondaryLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ marginLeft: 30 }}
                        >
                            {secondaryLinkLabel}
                        </Button>
                    )}
                </CardActions>
            </div>
        </Card>
    );
};

WorkCard.defaultProps = {
    eventLocation: null,
    imageSrc: null,
    primaryLink: null,
    primaryLinkLabel: null,
    secondaryLink: null,
    secondaryLinkLabel: null,
};

WorkCard.propTypes = {
    eventName: PropTypes.string.isRequired,
    eventDate: PropTypes.string.isRequired,
    eventLocation: PropTypes.string,
    description: PropTypes.string.isRequired,
    imageSrc: PropTypes.node,
    primaryLink: PropTypes.string,
    primaryLinkLabel: PropTypes.string,
    secondaryLink: PropTypes.string,
    secondaryLinkLabel: PropTypes.string,
};

export default WorkCard;
