import DevBreak2021 from './DevBreak_social.png';
import EggheadLogo from './eggheadlogo.png';
import ETLS2024 from './ETLS_2024.png';
import GirlGeekXElevate2024 from './Girl_Geek_X_ELEVATE_2024.jpeg';
import LeadDevAdventCalendar from './leaddev_advent_calendar.png';
import LeadDevStaffPlusOnboarding from './LeadDev_Staff_Plus_onboarding.jpeg';
import QConSF2023 from './QCon_SF_2023.jpg';
import Reactathon2018 from './Reactathon_Sept_2018.jpeg';
import Reactathon2019 from './Reactathon_Mar_2019.jpeg';
import ReactGlobalSummit2020 from './ReactGlobalSummit2020.png';
import RefactrTech2022 from './REFACTR.TECH_2022.png';
import ShopifyLogo from './shopify_logo_small.jpeg';
import SoloCoderPodcast from './solo.coder_podcast.jpeg';

const works = [
    {
        eventName: 'Enterprise Tech Leadership Summit Las Vegas (formerly DevOps Enterprise Summit)',
        eventDate: 'Aug 20-22 2024',
        eventLocation: 'Las Vegas, NV',
        description: 'Psychological Safety: It\'s Not Just About Throwing Chairs',
        imageSrc: ETLS2024,
        primaryLink: 'https://itrevolution.com/product/enterprise-technology-leadership-summit-las-vegas-2024/',
        primaryLinkLabel: 'COMING SOON - REGISTER'
    },
    {
        eventName: 'Girl Geek X ELEVATE',
        eventDate: 'Mar 7-8 2024',
        eventLocation: 'Virtual',
        description: 'Learning in Public and Working Out Loud',
        imageSrc: GirlGeekXElevate2024,
        primaryLink: 'https://girlgeek.io/learning-in-public-working-out-loud-erin-doyle-lob-video-transcript/',
        primaryLinkLabel: 'recording'
    },
    {
        eventName: 'InfoQ Article: Tips on How Staff Engineers Can Impact Incidents',
        eventDate: 'Dec 29 2023',
        eventLocation: 'Virtual',
        description: 'Article based on my talk at QCon San Francisco 2023',
        imageSrc: QConSF2023,
        primaryLink: 'https://www.infoq.com/articles/staff-engineers-impact-incidents',
        primaryLinkLabel: 'article',
    },
    {
        eventName: 'LeadDev Written Contribution: Increasing visibility by learning and working in public',
        eventDate: 'Dec 22 2023',
        eventLocation: 'Virtual',
        description: 'Contribution to the LeadDev 2023 Advent Calendar',
        imageSrc: LeadDevAdventCalendar,
        primaryLink: 'https://leaddev.com/advent-calendar',
        primaryLinkLabel: 'article'
    },
    {
        eventName: 'LeadDev Webinar Panel: Stress free onboarding as a Staff+ engineer',
        eventDate: 'Nov 7 2023',
        eventLocation: 'Virtual',
        description: 'What can you do to create an efficient onboarding process for yourself stepping into a Staff+ role?',
        imageSrc: LeadDevStaffPlusOnboarding,
        primaryLink: 'https://leaddev.com/videos/stress-free-onboarding-staff-engineer',
        primaryLinkLabel: 'recording'
    },
    {
        eventName: 'QCon San Francisco',
        eventDate: 'Oct 2-4 2023',
        eventLocation: 'San Francisco, CA',
        description: 'An Incident Story: Tips for How Staff+ Engineers Can Impact Incidents',
        imageSrc: QConSF2023,
        primaryLink: 'https://www.infoq.com/presentations/staff-engineer-incident',
        primaryLinkLabel: 'recording',
        secondaryLink: 'https://docs.google.com/presentation/d/1lPpsd8Uvn3TG_iEsPOtWRmqZjqJGe8xyHWRiF7S-tYM/edit?usp=sharing',
        secondaryLinkLabel: 'slides'
    },
    {
        eventName: 'REFACTR.TECH',
        eventDate: 'Sept 2022',
        eventLocation: 'Atlanta, GA',
        description: 'How to hit the ground running as a Staff+ Software Engineer at a new company',
        imageSrc: RefactrTech2022,
        primaryLink: 'https://youtu.be/PKm39Bsc5rk',
        primaryLinkLabel: 'recording',
        secondaryLink: 'https://docs.google.com/presentation/d/1zpFiiesUtBkeOXykOAUaVi1e8sLRwoV7BLJSsSdkX9A/edit?usp=sharing',
        secondaryLinkLabel: 'slides'
    },
    {
        eventName: 'DevBreak',
        eventDate: 'Sept 2021',
        eventLocation: 'Château de Farcheville, Bouville, France',
        description: 'Closing Keynote Panel on "The Importance of Web Accessibility"',
        imageSrc: DevBreak2021,
        primaryLink: 'https://youtu.be/WQzhQR7yIWM',
        primaryLinkLabel: 'recording',
    },
    {
        eventName: 'Shopify blog quote',
        eventDate: 'Apr 2021',
        description: 'Quoted in the article <u>Mobile Accessibility: How to Build Apps that Work for All Users</u> by Oliver Lindberg',
        imageSrc: ShopifyLogo,
        primaryLink: 'https://www.shopify.com/partners/blog/mobile-accessibility',
        primaryLinkLabel: 'article',
    },
    {
        eventName: 'React Global Online Summit',
        eventDate: 'Sept 2020',
        eventLocation: 'virtual',
        description: 'Accessibility Tips and Tricks',
        imageSrc: ReactGlobalSummit2020,
        primaryLink: 'https://www.youtube.com/watch?v=tERpF2agGs8&t=164s',
        primaryLinkLabel: 'recording',
        secondaryLink: 'https://erin-doyle-react-global-summit-2020.netlify.app',
        secondaryLinkLabel: 'slides'
    },
    {
        eventName: 'solo.coder podcast',
        eventDate: 'Episode 49',
        description: 'Spoke with Aderson Oliveira on the solo.coder podcast episode <u>Beautiful Code and Accessibility</u>',
        imageSrc: SoloCoderPodcast,
        primaryLink: 'https://solocoder.com/podcast/erin-doyle-beautiful-code-and-accessibility',
        primaryLinkLabel: 'podcast',
    },
    {
        eventName: 'egghead.io developer chats Podcast',
        eventDate: 'Episode 62',
        description: 'Spoke with Joel Hooks on the egghead.io developer chats podcast episode <u>Test Driven Accessibility with Erin Doyle</u>',
        imageSrc: EggheadLogo,
        primaryLink: 'https://egghead.io/podcasts/test-driven-accessibility-with-erin-doyle',
        primaryLinkLabel: 'podcast',
    },
    {
        eventName: 'Reactathon',
        eventDate: 'Mar 2019',
        eventLocation: 'San Francisco, CA',
        description: 'Taught workshop on <u>Building Accessible Web Apps with React</u>',
        imageSrc: Reactathon2019,
        primaryLink: 'https://github.com/erin-doyle/reactathon-a11y-workshop-2',
        primaryLinkLabel: 'Workshop repo',
        secondaryLink: 'https://docs.google.com/presentation/d/1Hys8ianCVUsLBZS5dt4sdIKrBq6royAQ0lm_DIForVI/edit?usp=sharing',
        secondaryLinkLabel: 'Slides'
    },
    {
        eventName: 'Reactathon',
        eventDate: 'Sept 2018',
        eventLocation: 'San Francisco, CA',
        description: 'Taught workshop on <u>Building Accessible Web Apps with React</u>',
        imageSrc: Reactathon2018,
        primaryLink: 'https://github.com/erin-doyle/reactathon-a11y-workshop',
        primaryLinkLabel: 'Workshop repo',
    }
];

export default works;
